import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import 'bootstrap/dist/css/bootstrap.min.css';
import addresses from './address.json';
import { Form, Input, Button, Row, Col, Select} from 'antd';

const VCardQRGenerator = () => {
  const [vCardString, setVCardString] = useState('');

  const handleSubmit = async (formData) => {
    if (formData?.fullname !== undefined && formData.designation !== undefined && formData.organisation !== undefined && formData.email !== undefined && formData.workPhone !== undefined && formData.workMobile !== undefined && formData.companyWebsiteUrl !== undefined && formData.companyAddress !== undefined) {
      const { fullname, organisation, designation, email, workPhone, workMobile, companyWebsiteUrl, companyAddress } = formData;
      
      const vCard = `BEGIN:VCARD\nN:${fullname}\nTITLE:${designation}\nORG:${organisation}\nEMAIL:${email}\nTEL;TYPE=WORK,VOICE:${workPhone}\nTEL;TYPE=WORK,CELL:${workMobile}\nURL:${companyWebsiteUrl}\nADR;TYPE=WORK:${companyAddress}\nEND:VCARD`;
      
      setVCardString(vCard);
    } else {
      alert('Please fill in all required fields.'); // Prompt user to fill in missing fields
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-5">VCard QR Code Generator</h1>
      <Form 
      onFinish={handleSubmit} 
      initialValues={{ companyWebsiteUrl: 'www.seweurodriveindia.com', organisation: 'SEW-EURODRIVE INDIA PVT. LTD.' }}
      layout='vertical'
      >
        <Row gutter={[16, 16]}>
          <Col md={9}>
            <Form.Item label="Full Name" name="fullname" rules={[{ required: true, message: 'Please enter your full name' }]}>
              <Input placeholder="Full Name" />
            </Form.Item>
            <Form.Item label="Title/Designation" name="designation" rules={[{ required: true, message: 'Please enter your title/designation' }]}>
              <Input placeholder="Designation" />
            </Form.Item>
            <Form.Item label="Organisation" name="organisation" rules={[{ required: true, message: 'Please enter your organisation' }]}>
              <Input placeholder="Organisation" />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}>
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col md={9}>
            <Form.Item label="Work Phone" name="workPhone" rules={[{ required: true, message: 'Please enter your work phone number' }]}>
              <Input placeholder="Work Phone" />
            </Form.Item>
            <Form.Item label="Work Mobile" name="workMobile" rules={[{ required: true, message: 'Please enter your work mobile number' }]}>
              <Input placeholder="Work Mobile" />
            </Form.Item>
            <Form.Item label="Company Website Url" name="companyWebsiteUrl" rules={[{ required: true, message: 'Please enter your company website URL' }]}>
              <Input placeholder="Company Website Url" />
            </Form.Item>
            <Form.Item label="Company Address" name="companyAddress" rules={[{ required: true, message: 'Please select your company address' }]}>
              <Select placeholder="Select company address" showSearch>
                {addresses && addresses.map((address, index) => (
                  <Select.Option value={address.value} key={index}>{address.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={6}>
            {vCardString && <QRCode
              value={vCardString}
              size={150}
              level="M"
              renderAs="canvas"
              includeMargin={false}
              bgColor="#fff"
              fgColor="#000"
              icon=""
            />}
          </Col>
          <Col md={24}>
            <Button type="primary" htmlType="submit">Generate QR Code</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default VCardQRGenerator;
